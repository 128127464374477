var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('SettingsTopBar',{attrs:{"pathCurrent":"CMS","pathBefore":"Settings /","showBackButton":true,"showSubmitButton":false}}),_c('div',{staticClass:"news-cms-wrapper"},[_c('div',{staticClass:"news__top-bar"},[_c('div',{staticClass:"news-cms__span-wrapper"},[_c('span',{class:{ 'is-active': _vm.isArchivedFilterOn },on:{"click":function($event){_vm.isArchivedFilterOn = true}}},[_vm._v(_vm._s(_vm.$t('Archived')))]),_c('div',{staticClass:"divider"}),_c('span',{class:{ 'is-active': !_vm.isArchivedFilterOn },on:{"click":function($event){_vm.isArchivedFilterOn = false}}},[_vm._v(_vm._s(_vm.$t('Active')))])]),_c('div',{staticClass:"news-cms__button-wrapper"},[_c('a-button',{staticClass:"news__archive-button",attrs:{"type":"secondary"},on:{"click":_vm.confirmArchivingOrActivating}},[_vm._v(_vm._s(_vm.isArchivedFilterOn ? _vm.$t('Activate') : _vm.$t('Archive')))]),_c('a-button',{staticClass:"news__create-button",attrs:{"type":"primary"},on:{"click":_vm.addNew}},[_vm._v(_vm._s(_vm.$t('Create')))]),_c('a-button',{staticClass:"news__delete-button",attrs:{"type":"danger"},on:{"click":_vm.confirmDelete}},[_vm._v(_vm._s(_vm.$t('Delete')))])],1)]),_c('div',{staticClass:"news-cms-content"},[_c('div',{staticClass:"search-bar"},[_c('a-input-search',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Search news data..."},on:{"keyup":(e) => _vm.onSearch(e.target.value)},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('div',{staticClass:"news-table-wrapper"},[_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.activeNews,"pagination":{ pageSize: 8 },"row-selection":{
                        selectedRowKeys: _vm.selectedRowKeys,
                        onChange: _vm.onSelectChange,
                    },"rowClassName":() => 'news__table-row',"rowKey":(record) => record.key,"customRow":(record) => {
                            return {
                                on: {
                                    click: () => _vm.openNewsReport(record.key), // click row
                                },
                            };
                        }}})],1)]),_c('a-modal',{attrs:{"ok-text":_vm.$t(_vm.activeNewsId ? 'Save' : 'Add'),"cancel-text":_vm.$t('Cancel'),"destroyOnClose":"","wrapClassName":"news-modal","centered":"","width":"80vw"},on:{"ok":_vm.createReport,"cancel":_vm.closePopup},model:{value:(_vm.isNewsModalVisible),callback:function ($$v) {_vm.isNewsModalVisible=$$v},expression:"isNewsModalVisible"}},[_c('news-add-new',{attrs:{"id":_vm.activeNewsId,"isAdmin":_vm.isSuperAdmin},on:{"closePopup":_vm.closePopup}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }